import RouterManager from './components/barba'
import { debounce, pairHeights } from './util'

// import { initWaves } from './components/waves'


document.addEventListener('DOMContentLoaded', () => {
  document.documentElement.className = 'js'
  RouterManager.init();
})


window.addEventListener('resize', debounce(() => {

}, 250))

window.addEventListener('load', () => {
})


window.addEventListener('resize', () => {
  pairHeights();
})

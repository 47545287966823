import Barba from 'barba.js'
import { initFleetSlider, initFooterSlider } from './flickity'
import { initMenu, reInitMenu } from './menu'
import { initParallax } from './parallax'
import { initCalendar } from './calendar'
import { initTimelines, firstLoadPageOverlay, loadPageOverlayIn, loadPageOverlayOut, disableOverlay} from './timelines'
import { debounce, pairHeights, $1, printConsoleHelicopter } from '../util'
var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent) && screen.width > 1000;

const RouterManager = (() => {
  const initTransition = function initTransition() {
    let destination
    Barba.Dispatcher.on('linkClicked', (el) => {
      destination = el.dataset.destination
    })

    Barba.Dispatcher.on('initStateChange', function() {
      if (typeof ga === 'function') {
        ga('send', 'pageview', location.pathname);
      }
    })

    const MainTransition = Barba.BaseTransition.extend({
      start: function start() {
        Promise
        .all([this.newContainerLoading, this.exit()])
        .then(this.finish.bind(this))
      },

      exit: function fadeOut() {
        const deferred = Barba.Utils.deferred()

        loadPageOverlayIn(function(){
          deferred.resolve()
        });


        return deferred.promise
      },

      finish: function finish() {
        // window.scroll(0, 0)
        // document.body.scrollTop = 0
        loadPageOverlayOut();
        this.done()
      },
    })

    Barba.Pjax.getTransition = () => MainTransition
  }

  const reInit = () => {
    // console.log('Barba.reInit')
    pairHeights();
    initFleetSlider();
    initFooterSlider();
    initCalendar();
    // initMenu();
    reInitMenu();
    // if(!is_safari){
    //   initParallax();
    // }
  }

  const initRouter = () => {
    Barba.Pjax.Dom.containerClass = 'l-site-container'
    Barba.Pjax.Dom.wrapperId = 'page-wrap'
    Barba.Pjax.ignoreClassLink = 'no-barba has-overlay ab-item'
    Barba.Utils.xhrTimeout = 10000

    Barba.Pjax.start()
    Barba.Prefetch.init()

    initTransition()

    Barba.Dispatcher.on('transitionCompleted', () => {
      reInit()
    })
  }

  const init = () => {
    initTimelines();
    initRouter()
    pairHeights();
    initFleetSlider();
    initFooterSlider();
    initCalendar();
    initMenu();
    printConsoleHelicopter();
    setTimeout(() => {


    if(!is_safari){
      firstLoadPageOverlay(function(){},true);
    } else{
      firstLoadPageOverlay(function(){},false);
    }
  }, 200);
  }

  return {
    init,
  }
})()

export default RouterManager

import Flickity from 'flickity'
import { $, each } from '../util'
import { TIMEOUT } from 'dns';

function initProtagonistiSlider(){
  var elem = document.querySelector('.c-careers-protagonisti__slider')
  if (elem != null){
    var flktyProtagonisti = new Flickity(elem, {
      cellAlign: 'left',
      cellSelector: '.c-careers-protagonisti__slide',
      prevNextButtons: false,
      pageDots: false,
      contain: true,
    });

    document.querySelector('.c-careers-protagonisti__total-number').innerText =  flktyProtagonisti.cells.length;
    document.querySelector('.c-careers-protagonisti__title-holder').innerText =  flktyProtagonisti.cells[0].element.dataset.title;

    var beforeArrow = document.querySelector('.c-careers-protagonisti__arrow-left');
    var nextArrow = document.querySelector('.c-careers-protagonisti__arrow-right');

    beforeArrow.addEventListener('click',function(){
      flktyProtagonisti.previous(true, false);
    });

    nextArrow.addEventListener('click',function(){
      flktyProtagonisti.next(true, false);
    });

    flktyProtagonisti.on("change",function(index){
      document.querySelector('.c-careers-protagonisti__changing-number').innerText =  index+1;
      document.querySelector('.c-careers-protagonisti__title-holder').innerText =  flktyProtagonisti.cells[index].element.dataset.title;
    });
  return flktyProtagonisti;
  }
}

function initHeliSlider(){
  let elem = document.querySelector('.c-heli-slider')
  if (elem != null){
    let flktyHeli = new Flickity(elem, {
      cellAlign: 'left',
      cellSelector: '.c-heli-slider-cell',
      prevNextButtons: false,
      pageDots: false,
      contain: true,
    });

    document.querySelector('.c-heli-slider__total-number').innerText =  flktyHeli.cells.length;
    document.querySelector('.c-heli-slider__title-holder').innerText =  flktyHeli.cells[0].element.dataset.title;

    let beforeArrow = document.querySelector('.c-heli-slider__arrow-left');
    let nextArrow = document.querySelector('.c-heli-slider__arrow-right');

    beforeArrow.addEventListener('click',function(){
      flktyHeli.previous(true, false);
    });

    nextArrow.addEventListener('click',function(){
      flktyHeli.next(true, false);
    });

    flktyHeli.on("change",function(index){
      document.querySelector('.c-heli-slider__changing-number').innerText =  index+1;
      document.querySelector('.c-heli-slider__title-holder').innerText =  flktyHeli.cells[index].element.dataset.title;
    });
  return flktyHeli;
  }
}

//TODO PULIRE QUI SOTTO

function initHeliSlider2(){
  let elem = document.querySelector('.c-heli-slider--2')
  if (elem != null){
    let flktyHeli2 = new Flickity(elem, {
      cellAlign: 'left',
      cellSelector: '.c-heli-slider-cell',
      prevNextButtons: false,
      pageDots: false,
      contain: true,
    });

    document.querySelector('.c-heli-slider__total-number--2').innerText =  flktyHeli2.cells.length;
    document.querySelector('.c-heli-slider__title-holder--2').innerText =  flktyHeli2.cells[0].element.dataset.title;

    let beforeArrow = document.querySelector('.c-heli-slider__arrow-left--2');
    let nextArrow = document.querySelector('.c-heli-slider__arrow-right--2');

    beforeArrow.addEventListener('click',function(){
      flktyHeli2.previous(true, false);
    });

    nextArrow.addEventListener('click',function(){
      flktyHeli2.next(true, false);
    });

    flktyHeli2.on("change",function(index){
      document.querySelector('.c-heli-slider__changing-number--2').innerText =  index+1;
      document.querySelector('.c-heli-slider__title-holder--2').innerText =  flktyHeli2.cells[index].element.dataset.title;
    });
  return flktyHeli2;
  }
}
function initHeliSlider1(){
  let elem = document.querySelector('.c-heli-slider--1')
  if (elem != null){
    let flktyHeli1 = new Flickity(elem, {
      cellAlign: 'left',
      cellSelector: '.c-heli-slider-cell',
      prevNextButtons: false,
      pageDots: false,
      contain: true,
    });

    document.querySelector('.c-heli-slider__total-number--1').innerText =  flktyHeli1.cells.length;
    document.querySelector('.c-heli-slider__title-holder--1').innerText =  flktyHeli1.cells[0].element.dataset.title;

    let beforeArrow = document.querySelector('.c-heli-slider__arrow-left--1');
    let nextArrow = document.querySelector('.c-heli-slider__arrow-right--1');

    beforeArrow.addEventListener('click',function(){
      flktyHeli1.previous(true, false);
    });

    nextArrow.addEventListener('click',function(){
      flktyHeli1.next(true, false);
    });

    flktyHeli1.on("change",function(index){
      document.querySelector('.c-heli-slider__changing-number--1').innerText =  index+1;
      document.querySelector('.c-heli-slider__title-holder--1').innerText =  flktyHeli1.cells[index].element.dataset.title;
    });
  return flktyHeli1;
  }
}

function initAeroSlider(){
  var elem = document.querySelector('.c-aero-slider')

  if (elem != null){
    var flktyAero = new Flickity(elem, {
      cellAlign: 'left',
      cellSelector: '.c-aero-slider-cell',
      prevNextButtons: false,
      pageDots: false,
      contain: true
    });

    document.querySelector('.c-aero-slider__total-number').innerText =  flktyAero.cells.length;
    document.querySelector('.c-aero-slider__title-holder').innerText =  flktyAero.cells[0].element.dataset.title;

    var beforeArrow = document.querySelector('.c-aero-slider__arrow-left');
    var nextArrow = document.querySelector('.c-aero-slider__arrow-right');

    beforeArrow.addEventListener('click',function(){
      flktyAero.previous(true, false);
    });

    nextArrow.addEventListener('click',function(){
      flktyAero.next(true, false);
    });

    flktyAero.on("change",function(index){
      document.querySelector('.c-aero-slider__changing-number').innerText =  index+1;
      document.querySelector('.c-aero-slider__title-holder').innerText =  flktyAero.cells[index].element.dataset.title;
    });

    return flktyAero;
  }
}

function initServicesSlider(){
  var elem = document.querySelector('.c-services-dots__container')

  if (elem != null){
    var flktyService = new Flickity(elem, {
      cellAlign: 'left',
      cellSelector: '.c-services-dot__container',
      prevNextButtons: false,
      adaptiveHeight: true,
      pageDots: false,
      contain: true
    });

    each(elem.getElementsByClassName('c-services-dot__container'), (i, dot_container) => {
      dot_container.addEventListener( 'mouseenter', function(){
        // console.log(this.dataset.index - 1);
        flktyService.select( this.dataset.index - 1 );
      });
    })
  }
}

function initStoriesSlider(){
  var elem = document.querySelector('.c-stories-dots__container')

  if (elem != null){
    var flktyService = new Flickity(elem, {
      cellAlign: 'left',
      cellSelector: '.c-services-dot__container',
      prevNextButtons: false,
      adaptiveHeight: true,
      pageDots: false,
      contain: true
    });

    each(elem.getElementsByClassName('c-services-dot__container'), (i, dot_container) => {
      dot_container.addEventListener( 'mouseenter', function(){
        // console.log(this.dataset.index - 1);
        flktyService.select( this.dataset.index - 1 );
      });
    })
  }
}

function initCitazioniSlider(){
  var elem_aereo = document.querySelector('.c-citazioni__aereo')

  if (elem_aereo != null){
    var flktyCit1 = new Flickity(elem_aereo, {
      cellAlign: 'left',
      cellSelector: '.c-citazione__container',
      prevNextButtons: false,
      adaptiveHeight: false,
      autoPlay: 5000,
      pageDots: true,
      contain: true
    });
  }


  var elem_heli = document.querySelector('.c-citazioni__heli')

  if (elem_heli != null){
    var flktyCit2 = new Flickity(elem_heli, {
      cellAlign: 'left',
      cellSelector: '.c-citazione__container',
      prevNextButtons: false,
      adaptiveHeight: false,
      autoPlay: 5000,
      pageDots: true,
      contain: true
    });

    // each(elem.getElementsByClassName('c-services-dot__container'), (i, dot_container) => {
    //   dot_container.addEventListener( 'mouseenter', function(){
    //     console.log(this.dataset.index - 1);
    //     flktyService.select( this.dataset.index - 1 );
    //   });
    // })
  }
}

function fleetMenuSlider(flktyHeli,flktyAero) {
  var fleet_menu = $('.c-fleet-slider-menu__link');
  var fleet_slider_containers = $('.c-general-slider__container');
  if (fleet_menu != null && flktyHeli != null && flktyAero != null ){
    each(fleet_menu, (i, menu) => {
      menu.addEventListener('click', function() {
        each(fleet_menu, (l, flMeun) => flMeun.classList.remove('c-fleet-slider-menu__link-active'))
        each(fleet_slider_containers, (l, flMeun) => flMeun.classList.remove('c-fleet-slider__active'))

        document.querySelector('.c-both-slider__container').classList.add("c-both-slider__container-rotating");

        if (this.innerText == "ELICOTTERI"){
          document.querySelector('.c-fleet-slider__heli-container').classList.add('c-fleet-slider__active');
          flktyHeli.resize();
        } else {
          document.querySelector('.c-fleet-slider__aero-container').classList.add('c-fleet-slider__active');
          flktyAero.resize();
        }

        setTimeout(function(){
          document.querySelector('.c-both-slider__container').classList.remove("c-both-slider__container-rotating");
        }, 250)
        this.classList.add('c-fleet-slider-menu__link-active');
      })
    })
  }

}
export function initFleetSlider () {
  initProtagonistiSlider()
  fleetMenuSlider(initHeliSlider(),initAeroSlider());
  initBigSlider();
  initServicesSlider();
  initStoriesSlider();
  initCitazioniSlider();
  initHeliSlider2()
  initHeliSlider1()
}


export function initFooterSlider(){
  var elem = document.querySelector('.c-footer-news__slider')
  var flktyFooter = new Flickity(elem, {
    cellAlign: 'left',
    cellSelector: '.c-footer-news__slider-cell',
    prevNextButtons: false,
    freeScroll: false,
    pageDots: false,
    contain: true
  });

  var beforeArrow = document.querySelector('.c-footer-news__slider__arrow-left');
  var nextArrow = document.querySelector('.c-footer-news__slider__arrow-right');

  beforeArrow.addEventListener('click',function(){
    flktyFooter.previous(true, false);
  });

  nextArrow.addEventListener('click',function(){
    flktyFooter.next(true, false);
  });

  flktyFooter.on("change",function(index){

  });
}





function initBigSlider(){
  var elem = document.querySelector('.c-aero-big-slider');
  var flktyHeli;
  var flktyAero;
  var aereo_visibile = false;
  if (elem != null){
    flktyAero = new Flickity(elem, {
      cellAlign: 'left',
      cellSelector: '.c-aero-big-slider-cell',
      prevNextButtons: false,
      pageDots: false,
      contain: true,
    });

    document.querySelector('.c-fleet-slider__total-number').innerText =  flktyAero.cells.length;
    document.querySelector('.c-fleet-slider__title-holder').innerText =  flktyAero.cells[0].element.dataset.title;

    var beforeArrow = document.querySelector('.c-fleet-slider__arrow-left');
    var nextArrow = document.querySelector('.c-fleet-slider__arrow-right');

    beforeArrow.addEventListener('click',function(){
      if (aereo_visibile){
        flktyAero.previous(true, false);
      }
    });

    nextArrow.addEventListener('click',function(){
      if (aereo_visibile){
        flktyAero.next(true, false);
      }
    });

    flktyAero.on("change",function(index){
      document.querySelector('.c-fleet-slider__changing-number').innerText =  index+1;
      document.querySelector('.c-fleet-slider__title-holder').innerText =  flktyAero.cells[index].element.dataset.title;
    });
  }

  var elem2 = document.querySelector('.c-heli-big-slider')
  if (elem2 != null){
    flktyHeli = new Flickity(elem2, {
      cellAlign: 'left',
      cellSelector: '.c-heli-big-slider-cell',
      prevNextButtons: false,
      pageDots: false,
      contain: true,
    });

    document.querySelector('.c-fleet-slider__total-number').innerText =  flktyHeli.cells.length;
    document.querySelector('.c-fleet-slider__title-holder').innerText =  flktyHeli.cells[0].element.dataset.title;

    var beforeArrow = document.querySelector('.c-fleet-slider__arrow-left');
    var nextArrow = document.querySelector('.c-fleet-slider__arrow-right');

    beforeArrow.addEventListener('click',function(){
      if (!aereo_visibile){
        flktyHeli.previous(true, false);
      }
    });

    nextArrow.addEventListener('click',function(){
      if (!aereo_visibile){
        flktyHeli.next(true, false);
      }
    });

    flktyHeli.on("change",function(index){
      document.querySelector('.c-fleet-slider__changing-number').innerText =  index+1;
      document.querySelector('.c-fleet-slider__title-holder').innerText =  flktyHeli.cells[index].element.dataset.title;
    });
  }

  var fleet_menu = $('.c-fleet-big-slider-menu__link');
  var fleet_slider_containers = $('.c-general-big-slider__container');
  var fleet_miniatures_containers = $('.c-fleet-big-slider-miniatures');
  var fleet_miniatures = $('.c-miniature');

  if (fleet_menu != null && flktyHeli != null && flktyAero != null ){
    each(fleet_menu, (i, menu) => {
      menu.addEventListener('click', function() {
        each(fleet_menu, (l, flMeun) => flMeun.classList.remove('c-fleet-slider-menu__link-active'))
        each(fleet_slider_containers, (l, flMeun) => flMeun.classList.remove('c-fleet-big-slider__active'))
        each(fleet_miniatures_containers, (l, flMin) => flMin.classList.remove('c-fleet-big-slider-miniatures__active'))

        document.querySelector('.c-general-big-slider__container').classList.add("c-both-slider__container-rotating");

        if (this.innerText == "ELICOTTERI"){
          document.querySelector('.c-fleet-big-slider__heli-container').classList.add('c-fleet-big-slider__active');
            flktyHeli.resize();
            flktyHeli.select( 0 );
            document.querySelector('.c-fleet-slider__changing-number').innerText =  1;
            document.querySelector('.c-fleet-slider__title-holder').innerText =  flktyHeli.cells[0].element.dataset.title;
            document.querySelector('.c-fleet-slider__total-number').innerText =  flktyHeli.cells.length;
            document.querySelector('.c-fleet-big-slider-heli-miniatures').classList.add('c-fleet-big-slider-miniatures__active');

            aereo_visibile = false;
        } else {
          document.querySelector('.c-fleet-big-slider__aero-container').classList.add('c-fleet-big-slider__active');
            flktyAero.resize();
            flktyAero.select( 0 );
            document.querySelector('.c-fleet-slider__changing-number').innerText =  1;
            document.querySelector('.c-fleet-slider__title-holder').innerText =  flktyAero.cells[0].element.dataset.title;
            document.querySelector('.c-fleet-slider__total-number').innerText =  flktyAero.cells.length;
            document.querySelector('.c-fleet-big-slider-aero-miniatures').classList.add('c-fleet-big-slider-miniatures__active');

            aereo_visibile = true;
        }

        setTimeout(function(){
          document.querySelector('.c-general-big-slider__container').classList.remove("c-both-slider__container-rotating");
        }, 250)
        this.classList.add('c-fleet-slider-menu__link-active');
      })
    })
    each(fleet_miniatures, (i, min) => {
      min.addEventListener('click', function() {
        if (this.dataset.slider == "heli"){
          flktyHeli.select( this.dataset.index - 1 );
        } else {
          flktyAero.select( this.dataset.index - 1 );
        }
      })
    })
  }
}

import { $, $1, each, debounce, hasSomeParentTheClass } from '../util'
import gumshoe from 'gumshoejs'
const smoothScroll = require('smooth-scroll')
const body = $1("body")
const nav = $1(".c-main-nav")
import {TweenMax, TimelineMax, Sine} from 'gsap'
var mouseOverMenu = false
var lastScrollTop = 0, footerOffset = 0, heroHeight = 0, pageWrap;

function menuManagerOnScroll(){
  var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  // console.log(scrollTop);
  // console.log(heroHeight);
  // console.log(footerOffset);

  // pageWrap menu
  if ( scrollTop > heroHeight - 50 && scrollTop < footerOffset - 50){
    pageWrap.classList.add("is-scrolling")
    // console.log('add is-scrolling')

  } else {
    pageWrap.classList.remove("is-scrolling")
    // console.log('removed is-scrolling')
  }

  if ( scrollTop > 0 && !mouseOverMenu ){
    body.classList.add("main-nav--is-idle")
  } else {
    body.classList.remove("main-nav--is-idle")
  }

  if (scrollTop > lastScrollTop){
      // downscroll code
  } else {
    body.classList.remove("main-nav--is-idle")
  }
  lastScrollTop = scrollTop;

  // School menu
  if ( scrollTop > footerOffset - 200){
    body.classList.add("school-nav--is-hidden")
  } else {
    body.classList.remove("school-nav--is-hidden")
  }
}

function onMenuScroll(){
  const hero = $1(".c-hero")
  const footer = $1(".c-footer-sitemap")
  heroHeight = 0
  lastScrollTop = 0;
  pageWrap = $1("#page-wrap")

  if( hero != null){
    heroHeight = hero.offsetHeight
    pageWrap.classList.remove("is-scrolling")
  } else {
    pageWrap.classList.add("is-scrolling")
  }

  footerOffset = footer.offsetTop

  if( footerOffset == 0 ){
    footerOffset = 60000
  }

  nav.onmouseenter = nav.onmouseleave = handlerMouseOverMenu;

  function handlerMouseOverMenu(event) {
    if (event.type == 'mouseenter') {
      mouseOverMenu = true
      body.classList.remove("main-nav--is-idle")
    }
    if (event.type == 'mouseleave') {
      mouseOverMenu = false
    }
  }

  window.removeEventListener("scroll", menuManagerOnScroll, false)
  window.addEventListener("scroll", menuManagerOnScroll, false)
}

function onVerticalMenuScroll(){
  gumshoe.init();
	var scroll = new smoothScroll('a[href*="#"]',{
    offset: -1
  });
}

function onMobileHamburgerClick(){
  const pageWrap = $1("#hamburger-menu")
  pageWrap.addEventListener("click", function(){
    if( body.classList.contains("is_mobile_menu_open") ){
      body.classList.remove("is_mobile_menu_open")
    } else {
      body.classList.add("is_mobile_menu_open")
    }
  });
}

function onMainMenuVoiceHover(){
  var menu_voices = $(".c-main-nav ul > .c-navbar__item, .c-school-navbar__item, #mobile-menu .c-navbar__item")
  var tl = new TimelineMax({repeat:0, repeatDelay:1});

  each(menu_voices, (i, menu_voice) => {

    menu_voice.addEventListener("click", function(){
      each(menu_voices, (i, menu_voice_l) => {
        menu_voice_l.classList.remove("current-menu-item")
        menu_voice_l.classList.remove("current_page_item")
      });
      menu_voice.classList.add("current-menu-item")
      menu_voice.classList.add("current_page_item")
    });
    menu_voice.classList.remove("current-menu-item")
    menu_voice.classList.remove("current_page_item")
    // if (menu_voice.querySelectorAll('.sub-menu').length > 0){

    //   var submenu = menu_voice.querySelectorAll('.sub-menu')[0]
    //   var submenu_voices = menu_voice.querySelectorAll('.sub-menu .c-navbar__item')
    //   var submenu_height = submenu.clientHeight;

    //   menu_voice.addEventListener("mouseenter", function(){
    //     each($(".c-navbar__item .active"), (i, submenu_voice) => {
    //       submenu_voice.classList.remove('active');
    //     });
    //     tl.to(nav, 0.5, {height:80+submenu_height+"px", ease:Expo.easeInOut});
    //     setTimeout(function(){
    //       each(submenu_voices, (i, submenu_voice) => {
    //         submenu_voice.classList.add('active');
    //       })
    //      }, 500);
    //   });

    //   menu_voice.addEventListener("mouseleave", function(){
    //     each(submenu_voices, (i, submenu_voice) => {
    //       submenu_voice.classList.remove('active');
    //     });
    //   });

    // } else {

    //   menu_voice.addEventListener("mouseenter", function(){

    //     if( !hasSomeParentTheClass(menu_voice, 'sub-menu')){
    //       tl.to(nav, 0.30, {height:"100px", ease:Expo.easeInOut,onComplete:function(){
    //         this.target.removeAttribute("style")
    //       }})
    //     }
    //   });

    // }
  })

  // $1('.c-main-nav__menu-school').addEventListener("mouseenter", function(){
  //   tl.to(nav, 0.30, {height:"420px", ease: Expo.easeInOut});
  // });

  // $1('.c-main-nav__menu-school').addEventListener("mouseleave", function(){
  //   tl.to(nav, 0.70, {height:"100px", ease:Expo.easeInOut,onComplete:function(){
  //     this.target.removeAttribute("style")
  //   }})
  // });

  // $1('.c-main-nav').addEventListener("mouseleave", function(){
  //   console.log("mouseleft")
  //   each($(".c-navbar__item .active"), (i, submenu_voice) => {
  //     submenu_voice.classList.remove('active');
  //   });
  //   tl.to(nav, 0.30, {height:"100px", ease:Expo.easeInOut,onComplete:function(){
  //     this.target.removeAttribute("style")
  //   }})
  // });



}


export function initMenu(){
  onMenuScroll();
  onVerticalMenuScroll();
  onMobileHamburgerClick();
  onMainMenuVoiceHover();
}

export function reInitMenu(){
  body.classList.remove("is-scrolling")
  body.classList.remove("is_mobile_menu_open")
  $1('#page-wrap').classList.add("is-scrolling")
  nav.removeAttribute("style")
  window.scrollTo(0,0)
  onMenuScroll();
  onVerticalMenuScroll();
}

import inlineSVG from 'inline-svg'
import {TweenMax, TimelineMax, Sine} from 'gsap'
import PhotoSwipe from 'photoswipe'
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'
import {listenForms} from './components/mailer'
// import inlineSVG from './components/barba'


var initPhotoSwipeFromDOM = function(gallerySelector) {

  // parse slide data (url, title, size ...) from DOM elements
  // (children of gallerySelector)
  var parseThumbnailElements = function(el) {
      var thumbElements = el.childNodes,
          numNodes = thumbElements.length,
          items = [],
          figureEl,
          linkEl,
          size,
          item;

      for(var i = 0; i < numNodes; i++) {

          figureEl = thumbElements[i]; // <figure> element

          // include only element nodes
          if(figureEl.nodeType !== 1) {
              continue;
          }
          if(figureEl.children[0] == undefined){
            continue;
          }

          linkEl = figureEl.children[0]; // <a> element

          if(linkEl.getAttribute('data-size') == undefined){
            continue;
          }

          size = linkEl.getAttribute('data-size').split('x');

          // create slide object
          item = {
              src: linkEl.getAttribute('href'),
              w: parseInt(size[0], 10),
              h: parseInt(size[1], 10)
          };



          if(figureEl.children.length > 1) {
              // <figcaption> content
              item.title = figureEl.children[1].innerHTML;
          }

          if(linkEl.children.length > 0) {
              // <img> thumbnail element, retrieving thumbnail url
              item.msrc = linkEl.children[0].getAttribute('src');
          }

          item.el = figureEl; // save link to element for getThumbBoundsFn
          items.push(item);
      }

      return items;
  };

  // find nearest parent element
  var closest = function closest(el, fn) {
      return el && ( fn(el) ? el : closest(el.parentNode, fn) );
  };

  // triggers when user clicks on thumbnail
  var onThumbnailsClick = function(e) {
      e = e || window.event;
      e.preventDefault ? e.preventDefault() : e.returnValue = false;

      var eTarget = e.target || e.srcElement;

      // find root element of slide
      var clickedListItem = closest(eTarget, function(el) {
          return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
      });

      if(!clickedListItem) {
          return;
      }

      // find index of clicked item by looping through all child nodes
      // alternatively, you may define index via data- attribute
      var clickedGallery = clickedListItem.parentNode,
          childNodes = clickedListItem.parentNode.childNodes,
          numChildNodes = childNodes.length,
          nodeIndex = 0,
          index;

      for (var i = 0; i < numChildNodes; i++) {
          if(childNodes[i].nodeType !== 1) {
              continue;
          }

          if(childNodes[i] === clickedListItem) {
              index = nodeIndex;
              break;
          }
          nodeIndex++;
      }



      if(index >= 0) {
          // open PhotoSwipe if valid index found
          openPhotoSwipe( index, clickedGallery );
      }
      return false;
  };

  // parse picture index and gallery index from URL (#&pid=1&gid=2)
  var photoswipeParseHash = function() {
      var hash = window.location.hash.substring(1),
      params = {};

      if(hash.length < 5) {
          return params;
      }

      var vars = hash.split('&');
      for (var i = 0; i < vars.length; i++) {
          if(!vars[i]) {
              continue;
          }
          var pair = vars[i].split('=');
          if(pair.length < 2) {
              continue;
          }
          params[pair[0]] = pair[1];
      }

      if(params.gid) {
          params.gid = parseInt(params.gid, 10);
      }

      return params;
  };

  var openPhotoSwipe = function(index, galleryElement, disableAnimation, fromURL) {
      var pswpElement = document.querySelectorAll('.pswp')[0],
          gallery,
          options,
          items;

      items = parseThumbnailElements(galleryElement);

      // define options (if needed)
      options = {
        hideAnimationDuration:0,
        showAnimationDuration:0,
        history:false, galleryPIDs:true,
          // define gallery index (for URL)
          galleryUID: galleryElement.getAttribute('data-pswp-uid'),

          getThumbBoundsFn: function(index) {
              // See Options -> getThumbBoundsFn section of documentation for more info
              var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
                  pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                  rect = thumbnail.getBoundingClientRect();

              return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
          }

      };

      // PhotoSwipe opened from URL
      if(fromURL) {
          if(options.galleryPIDs) {
              // parse real index when custom PIDs are used
              // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
              for(var j = 0; j < items.length; j++) {
                  if(items[j].pid == index) {
                      options.index = j;
                      break;
                  }
              }
          } else {
              // in URL indexes start from 1
              options.index = parseInt(index, 10) - 1;
          }
      } else {
          options.index = parseInt(index, 10);
      }

      // exit if index not found
      if( isNaN(options.index) ) {
          return;
      }

      if(disableAnimation) {
          options.showAnimationDuration = 0;
      }

      // Pass data to PhotoSwipe and initialize it
      gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
      gallery.init();
  };

  // loop through all gallery elements and bind events
  var galleryElements = document.querySelectorAll( gallerySelector );

  for(var i = 0, l = galleryElements.length; i < l; i++) {
      galleryElements[i].setAttribute('data-pswp-uid', i+1);
      galleryElements[i].onclick = onThumbnailsClick;
  }

  // Parse URL and open gallery if it contains #&pid=3&gid=1
  var hashData = photoswipeParseHash();
  if(hashData.pid && hashData.gid) {
      openPhotoSwipe( hashData.pid ,  galleryElements[ hashData.gid - 1 ], true, true );
  }
};


export function debounce(func, wait, immediate) {
  var timeout
  return function() {
    var context = this
    var args = arguments
    var later = function() {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export function throttle(callback, wait, context = this) {
  let timeout = null
  let callbackArgs = null

  const later = () => {
    callback.apply(context, callbackArgs)
    timeout = null
  }

  return function() {
    if (!timeout) {
      callbackArgs = arguments
      timeout = setTimeout(later, wait)
    }
  }
}

export function each(array, callback, scope) {
  for (let i = 0; i < array.length; i += 1) {
    callback.call(scope, i, array[i])
  }
}

export function $1(selector, context) {
  return (context || document).querySelector(selector)
}

export function $(selector, context) {
  return (context || document).querySelectorAll(selector)
}

export function whichTransitionEvent() {
  var t
  var el = document.createElement('fakeelement')
  var transitions = {
    'transition': 'transitionend',
    'OTransition': 'oTransitionEnd',
    'MozTransition': 'transitionend',
    'WebkitTransition': 'webkitTransitionEnd',
  }

  for(t in transitions) {
    if(el.style[t] !== undefined) {
      return transitions[t]
    }
  }
}

export function getClosest(elem, selector) {
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function(s) {
        var matches = (this.document || this.ownerDocument).querySelectorAll(s),
            i = matches.length;
        while (--i >= 0 && matches.item(i) !== this) {}
        return i > -1
      }
  }

  // Get the closest matching element
  for ( ; elem && elem !== document; elem = elem.parentNode ) {
    if ( elem.matches( selector ) ) return elem;
  }
  return null
}

export function insertAfter(newNode, referenceNode) {
  referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
          c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
      }
  }
  return "";
}

function cookieManager(){
  if( getCookie("policy_accepted") != ""){
    $1('.c-cookies').classList.add('hidden');
  } else {
    $1('.c-cookies').classList.remove('hidden');
  }
  $1('.js-accept-cookie').addEventListener("click", function(e){
    e.preventDefault();
    setCookie("policy_accepted", true, 90);
    $1('.c-cookies').classList.add('hidden');
  });
}

export function pairHeights(){
  const boxes_containers = $('.c-box__container')
  const footer_balls = $('.c-footer-news__ball, .c-team-member__ball, .c-who-we-are-team__ball')
  const mobile_menu_overlay = $1('.c-mobile-nav__overlay')
  const wave_mask_svg = $('.o-wave-mask-right')
  const news_holder = $('.c-news-archive')
  const school_archives = $('.c-school-archive')
  const overlay_links = $('.has-overlay')
  const services = $('.c-services-case__container')
  const body = $1("body")

  // Boxes
  each(boxes_containers, (i, boxes_container) => {
    var max_height = 0
    each(boxes_container.getElementsByClassName('c-box'), (i, box) => {
      box.style.height = null;
      if(box.clientHeight > max_height){
        max_height = box.clientHeight;
      }
    })
    each(boxes_container.getElementsByClassName('c-box'), (i, box) => {
      box.style.height = max_height + 'px'
    })
  })

  // Footer Balls
  setTimeout(function(){
    each(footer_balls, (i, ball) => {
      ball.style.height = ball.clientWidth + 'px'
    })
  }, 100)

  // Has Sub menu on menu voices
  each(mobile_menu_overlay.getElementsByClassName('c-navbar__item'), (i, menu_voice) => {
    if(menu_voice.getElementsByClassName('sub-menu').length > 0){
      menu_voice.classList.add('has-sub-menu');
    }
  })

  // News
  var max_news_height = 0
  each(news_holder, (i, box) => {
    box.style.height = null;
    if(box.clientHeight > max_news_height){
      max_news_height = box.clientHeight;
    }
  })
  each(news_holder, (i, box) => {
    box.style.height = max_news_height + 'px'
  })

  // Wave masks
  if(wave_mask_svg.length > 0){
    each(wave_mask_svg, (i, svg) => {
      var height = svg.clientWidth;
    })
  }

  // School Archives
  each($('.c-school-archive__container'), (i, box_parent) => {
    var max_school_archives_height = 0
    each(box_parent.getElementsByClassName('c-school-archive'), (i, box) => {
      if(!box.classList.contains("c-school-archive--f-w")){

      box.style.height = null;
      if(box.clientHeight > max_school_archives_height){
        max_school_archives_height = box.clientHeight;
      }
      // console.log("box",box.clientHeight)
      // console.log("box",box)
      }

    })
    each(box_parent.getElementsByClassName('c-school-archive'), (i, box) => {
      if(!box.classList.contains("c-school-archive--f-w")){
        box.style.height = max_school_archives_height + 'px'
      }
    })
  })

  // Services
  var max_services_height = 0
  each(services, (i, box) => {
    box.style.height = null;
    if(box.clientHeight > max_services_height){
      max_services_height = box.clientHeight;
    }
  })
  each(services, (i, box) => {
    box.style.height = max_services_height + 'px'
  })


  // General Overlays
  each(overlay_links, (i,link) => {
    var overlayTimeline = new TimelineMax({repeat:-1});
    overlayTimeline.timeScale(9);
    var tl = new TimelineMax({});
    let overlay = $1("#o-"+link.dataset.overlayId)
    if ( overlay ){
      tl.stop();
    tl.to(overlay, 0.6, { y: 0 })
    tl.to(overlay.querySelector('.c-general-overlay__content'), 0.6, { y: 0 }, "-=0.3")

    link.addEventListener("click", function(e){
      e.preventDefault();
        tl.play();
        let overlay_close = overlay.querySelector('.c-general-overlay__close')
        overlay_close.addEventListener("click", function(e){

          tl.reverse();
          setTimeout(() => {
            overlay.style = null;
            overlay.querySelector('.c-general-overlay__content').style = null;
          }, 1200);
        });
    });
    }
  })

  $1('.c-footer-form .email').addEventListener("change", function(){
    $1('#mc_embed_signup_scroll').classList.add('active');
  });

  // $1('.c-contact-form .privacy').addEventListener("change", function(){
  //   console.log(this.checked);
  //   if( this.checked ){
  //     each($('.submit-button'), (i, sb) => {
  //       sb.classList.remove("disabled-2");
  //     })
  //   }
  // });

  each($('.c-contact-form'), (i, cf) => {
    cf.querySelector(".privacy").addEventListener("change", function(){
      if( this.checked ){
        cf.querySelector(".submit-button").classList.remove("disabled-2");
      } else {
        cf.querySelector(".submit-button").classList.add("disabled-2");
      }
    })
    // cf.querySelector(".disabled-2").addEventListener("click", function(){
    //   cf.querySelector(".privacy-holder").classList.add("tada");
    //   setTimeout(function(){
    //     cf.querySelector(".privacy-holder").classList.add("tada");
    //   }, 300);
    // })
  })

  $1('.c-footer-form .subscribe').addEventListener("click", function(){
    $1('#mc_embed_signup_scroll').classList.remove('active');
  });

  if ( $(".c-school-nav__stories-desc").length > 0){
    body.classList.add("page-school-hamburger-menu-padd")
  } else {
    body.classList.remove("page-school-hamburger-menu-padd")
  }

  initPhotoSwipeFromDOM('.my-gallery');

  listenForms();

  cookieManager();

}


export function hasSomeParentTheClass(element, classname) {
  if (element.className == undefined) return false;
  if (element.className.split(' ').indexOf(classname)>=0) return true;
  return element.parentNode && hasSomeParentTheClass(element.parentNode, classname);
}


export function printConsoleHelicopter(){
  console.log(`                    .- . _
            .:       '      ^ - . _
     \     ::          '            ^^--..__                                                . . . :  :  :
     '\   ::  :'         ' _ _              ^^--..__                       ......_._._:.:.: ^ ^ ^ ^  ^  ^
      '\.::  :'                ^ ^ ^ - ----.....___ ^^-##.____...:::^^^^^^^^^^^^
       : :  :'                                     ^::^:^-----......______
     .^  ^^--..__ .                               .:'.:                   ^^^^^^-----. . . _ _ _
    /.^^--.._    ^^--..__ :                     .:'. :                                           ^  ^  ^
   /'   .:   ^:-.._      ^^--..__             .:'.  :
    '-._:__.-^     ^^-.._        ^^--...___..:'.   :
      .:               /.^^-..__...---^^-.'^-:^--.:_
                       ^      : ELIFRIULIA.'   ::^^-.^^;.
                             :_         :     ::    ^:: '-.
                             :::::.._   '-._ ::      ::    '.
                             : ^^^:::::.._  ^''-._  ::       :
                             :       ^^:::::.._   ^':         :
                             '.           ^^^:::::-:_         :
                               :.               ^^^::::::...   :
                     ..__..--^^ ^.      .              ^^^::::::
                        ^^--..__  :.--^^                      ^:
                                ^^-+:=:_                   (^-:
                                   :    ^^--.._             []'
                                   :          :^^--..___..--^
                                   :          :
                                 ^^--..__     :
                                         ^^--.:_
                                                ^^--..__.-`, "Made with love by https://digital.zeranta.com/")
}

import { $, $1, each } from '../util'

export function initCalendar(){
  const calendar = $1(".c-calendar")
  if( calendar != null){

  } else {

  }

}
import { $, $1, each, debounce } from '../util'
import reqwest from 'reqwest'
import { showSuccessFormAnimation, showSendingFormAnimation} from './timelines';

var couldSendEmail = true;
var submitForm = debounce(function(form) {
  if(couldSendEmail == false){
    return false;
  }

  couldSendEmail = false;
  var url = form.getAttribute("action");
  $1("body").classList.add("is_form--loading");
  // console.log('submitForm','mail request');
  reqwest({
    url: url,
    method: 'post',
    data: { name: form.querySelector('.name').value,
            email: form.querySelector('.email').value,
            ogg: form.querySelector('.ogg').value,
            msg: form.querySelector('.msg').value },
    success: function (resp) {

      setTimeout(function(){
        if( resp == "false"){
          $1("body").classList.remove("is_form--loading");
          $1("body").classList.add("is_form--error");
        } else {
          $1("body").classList.remove("is_form--loading");
          $1("body").classList.add("is_form--success");
        }


        setTimeout(function(){
          $1("body").classList.remove("is_form--success");
          $1("body").classList.remove("is_form--error");
          $1("body").classList.remove("is_form--loading");
          form.querySelector('.name').value = "";
          form.querySelector('.email').value = "";
          form.querySelector('.msg').value = "";
          couldSendEmail = true;
        }, 3000)
      }, 500)
    }
  })
}, 4000, true);
export function listenForms(){
  const forms = $('.c-general-contact-form')
  // console.log('listenForms','listenForms')
  // Forms

  each(forms, (i, form) => {

    if(!form.classList.contains("is_form--listening")){
      // console.log('form_di_invio_appeso_per', form)
      form.addEventListener("submit", function(e) { e.preventDefault(); submitForm(form); }, true)
      form.classList.add("is_form--listening");
    }
  })
}


import {TweenMax, TimelineMax, Sine} from 'gsap'
import { $, $1, each } from '../util'
export function initParallax(){
  const parallaxBlocks = $('.js-parallax-block')

  each(parallaxBlocks, (i, block) => {
    // block.addEventListener('mousemove', (e) => {
    //   const decimalX = (e.clientX / window.innerWidth) - 0.5
    //   const decimalY = (e.clientY / window.innerHeight) - 0.5

    //   TweenMax.to(block, 0.3, {
    //     rotationY: 20 * decimalX,
    //     rotationX: 20 * decimalY,
    //     ease: Quad.easeOut,
    //     transformPerspective: 1000,
    //     transformOrigin: 'center',
    //   })
    // })

    $1('.c-hero').addEventListener('mousemove', (e) => {
      const decimalX = (e.clientX / window.innerWidth) - 0.5
      const decimalY = (e.clientY / window.innerHeight) - 0.5

      TweenMax.to(block, 0.3, {
        rotationY: 20 * decimalX,
        rotationX: 20 * decimalY,
        // y: 20 * decimalX,
        // x: 20 * decimalY,
        ease: Quad.easeOut,
        transformPerspective: 1000,
        transformOrigin: 'center',
      })
    })
  })
}

import Vivus from 'vivus'
import {TweenMax, TimelineMax, Sine} from 'gsap'
import { $, $1, each } from '../util'
import inView from 'in-view'
import { initParallax } from './parallax'

inView.offset(400);
var tl_h,tl_h_c,tl_h_s;
var logo_container = $1('.c-loading-overlay__logo-container'), bg_container = $1('.c-loading-overlay__bg-container'),  ship = $1('.ship'), letters = $('.loader-letters-1'),tagline = $('.loader-letters-2'), both = $('.loader-letters-1, .loader-letters-2');
var overlay = $1('.c-loading-overlay__container')
var screenWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
var is_animation_active = true;
var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent) && screen.width > 1000;


// , onComplete: drawSVGCirclesHero
function drawSVGCircles(id1, id2){
  if (!is_safari && $1('#'+id2) && $1('#'+id1)){
  new Vivus(id1, {duration: 80,
      onReady: function () { $1('#'+id2).style.visibility = 'visible'; },
      }).play( function(){
      });

  new Vivus(id2, {duration: 60,
    onReady: function () { $1('#'+id1).style.visibility = 'visible'; },
    delay: 0.2,
    }).play(function(){
    });
    setTimeout(function(){
      $1('#'+id1).classList.add('a-orbit-1')
      $1('#'+id2).classList.add('a-orbit-2')
    }, 500)
  }
  if (is_safari && $1('#'+id2) && $1('#'+id1)) {
    $1('#'+id2).style.visibility = 'visible';
    $1('#'+id1).style.visibility = 'visible';
  }
}

function drawSVGCirclesHero(){
  drawSVGCircles('c-hero-svg-1','c-hero-svg-2');
}

function splitText(id){
  if ($1(id) && $1(id).getElementsByClassName('l')){
    var sentence = $1(id).querySelector('.hidden').innerText
    var splitSentence = ""
    for(var i=0; i<sentence.length; i++) {
      if (sentence.charAt(i) == " "){
        splitSentence += "<span><div class='l'>"+sentence.charAt(i)+"</div>"
      } else {
        splitSentence += "<div class='l'>"+ sentence.charAt(i) + "</div>"
      }
    };
    splitSentence += "</span><div class='hidden'>"+sentence+"</div>";

    $1(id).innerHTML = splitSentence

    return splitSentence;
  }
}

export function initHomeHeroTimeline(){
  if(document.getElementsByClassName('c-hero')){
      if(!is_safari){
      $1('#page-wrap').classList.remove("is_scrolling")

      tl_h = new TimelineMax({repeat:0, repeatDelay:1});
      tl_h.stop();
      splitText("#c-hero-title")


      if ( screenWidth < 900 ){
        tl_h.fromTo(".c-vertical-navigation__line-2", 0.3, {x: -200}, {x:0})
        tl_h.to(".c-vertical-navigation__line-2 .line", 0.2,{height:"100%"});
      } else {
        tl_h.to(".c-vertical-navigation__line .line", 0.4, {height:"100%"}) ;
        tl_h.staggerFromTo($(".c-vertical-navigation__balls li"), 0.2, { opacity: 0, scale: 0 }, { opacity: 1,scale:1}, 0.1, '-=0.2')
        tl_h.to(".c-vertical-navigation__line-2 .line", 0.3,{height:"100%"}, '-=0.3');
      }

      tl_h.fromTo(".c-scroll-copy", 0.3, {x: 70}, {x:0})
      tl_h.fromTo("#c-hero-box .before-line", 0.25, { width: 0, }, { width: "calc( 10vw - 49px)"}, '-=0.25');

      tl_h.staggerFromTo($("#c-hero-box .top-line"), 0.20, { width: 0, }, { width: "100%"}, 0.15, '-=0.15')
      tl_h.staggerFromTo($("#c-hero-box .left-line"), 0.25, { height: 0, }, { height: "100%"}, 0.1, '-=0.25')
      tl_h.staggerFromTo($("#c-hero-box .right-line"), 0.25, { height: 0, }, { height: "100%"}, 0.1, '-=0.25')

      tl_h.staggerFromTo($("#c-hero-box .c-box__title"), 0.35, { opacity: 0, x: '-10px' }, { opacity: 1 , x: '0px', ease: Expo.easeInOut}, 0.1, '-=0.35')
      tl_h.staggerFromTo($("#c-hero-box .c-box__copy"), 0.35, { opacity: 0 }, { opacity: 1, ease: Expo.easeInOut}, 0.1, '-=0.255')
      tl_h.staggerFromTo($("#c-hero-box .c-box__cta"), 0.35, { opacity: 0, x: '10px', ease: Expo.easeInOut }, { opacity: 1 , x: '0px'}, 0.1, '-=0.4')
      tl_h.fromTo("#c-hero-box .bottom-line", 0.6, { width: 0, }, { width: "100%"}, '-=0.4');

      tl_h.fromTo("#c-hero-ball", 0.8, { opacity: 1, scale: .0, x: '0px',y: '0px',rotation:"1.25rad" }, { rotation:"0rad", scale: 1, x: '0px',y: '0px', ease: Expo.easeInOut}, '-=1');


      tl_h.fromTo("#c-hero-subtitle", 0.7, { opacity: 0, x: '-10px' }, { opacity: 1 , x: '0px', ease: Expo.easeInOut}, '-=0.7');
      tl_h.fromTo("#c-hero-title-divider", 0.7, { width: 0, }, { width: "50px"}, '-=0.7');
      tl_h.staggerFromTo($("#c-hero-title .l"), 0.4, {scale: .9, opacity: 0}, { scale: 1, opacity: 1, onComplete: drawSVGCirclesHero}, 0.05, '-=0.4')
      tl_h.fromTo($("#c-hero-ghost"), 0.8, {scale: 1.2, opacity: 0}, { scale: 1, opacity: 0.1, onComplete: initParallax})


      inView('#hero')
      .on('enter', function(){
        $1('#c-hero-svg-1').classList.add('a-orbit-1')
        $1('#c-hero-svg-2').classList.add('a-orbit-2')
      })
      .on('exit', el => {
        if(document.getElementById('c-hero-svg-1') ){
          $1('#c-hero-svg-1').classList.remove('a-orbit-1')
          $1('#c-hero-svg-2').classList.remove('a-orbit-2')
        }
      });
    } else {
      TweenMax.set(".c-vertical-navigation__line .line", {height:"100%"}) ;
      TweenMax.set(".c-vertical-navigation__line-2 .line", {height:"100%"}) ;
    }
  }

  // Safari callback


}

export function initHomeInView(){

    if (false){ // <- Remove me before flight
    if(document.getElementsByClassName('c-hero') && screenWidth > 1100){
      // COURSES
      tl_h_c = new TimelineMax({repeat:0, repeatDelay:1});
      tl_h_c.stop();
      TweenMax.set("#c-h-c-ball", {scale: .0});
      $1("#c-h-c-svg-1").style.visibility = "hidden";
      $1("#c-h-c-svg-2").style.visibility = "hidden";
      tl_h_c.fromTo("#c-h-c-ball", 0.8, { opacity: 1, scale: .0, x: '0px',y: '0px',rotation:"1.25rad" }, { rotation:"0rad", scale: 1, x: '0px',y: '0px', ease: Expo.easeInOut, onComplete: function(){drawSVGCircles('c-h-c-svg-1','c-h-c-svg-2')}});
      tl_h_c.staggerFromTo($("#courses .c-case__icon"), 0.4, { opacity: 0, y: '-10px' }, { opacity: 1 , y: '0px', ease: Expo.easeInOut}, 0.2)
      tl_h_c.staggerFromTo($("#courses .c-case__title"), 0.4, { opacity: 0 }, { opacity: 1, ease: Expo.easeInOut}, 0.2, '-=0.45')
      tl_h_c.staggerFromTo($("#courses .c-case__copy"), 0.4, { opacity: 0, x: '-10px', ease: Expo.easeInOut }, { opacity: 1 , x: '0px'}, 0.2, '-=0.4')

      inView('#courses .o-col-fifth')
      .on('enter', function(){
        tl_h_c.play();
      })
      .on('exit', el => {

        if(document.getElementById('c-hero-svg-1') ){
          $1('#c-h-c-svg-1').classList.remove('a-orbit-1')
          $1('#c-h-c-svg-2').classList.remove('a-orbit-2')
        }
      });



      // SERVICES
      tl_h_s = new TimelineMax({repeat:0, repeatDelay:1});
      tl_h_s.stop();
      // tl_h_s.fromTo("#wave", 1, { opacity: 0}, { opacity:0.8});
      // tl_h_s.fromTo("#wave02", 1, { opacity: 0}, { opacity:0.8}, "-=0.2");
      // tl_h_s.fromTo("#wave03", 1, { opacity: 0}, { opacity:0.8}, "-=0.2");
      tl_h.staggerFromTo($(".c-services-dot__container"), 0.4, { opacity: 0 }, {opacity: 1}, 0.1)

    }
  } //  <- Remove me before flight IF FALSE FADE CLOSE

  inView('#services .c-services__waves')
    .on('enter', function(){
      // tl_h_s.play(); //  <- Remove me before flight RIATTIVA FADE animazioni

      $1('#wave').classList.add('a-wave-orbit-1')
      $1('#wave02').classList.add('a-wave-orbit-2')
      $1('#wave03').classList.add('a-wave-orbit-3')

    })
    .on('exit', el => {
      if(document.getElementById('wave') ){
      $1('#wave').classList.remove('a-wave-orbit-1')
      $1('#wave02').classList.remove('a-wave-orbit-2')
      $1('#wave03').classList.remove('a-wave-orbit-3')
      }
    });
}

export function initTimelines(){
  initHomeHeroTimeline();
  initHomeInView();
}

function playHomeTimeline(barbaCallBack){
  if(document.getElementsByClassName('c-hero') && !is_safari){
    if(tl_h != undefined){
      tl_h.play();
    }
  }

  if(barbaCallBack){
    barbaCallBack();
  }
}

export function firstLoadPageOverlay(barbaCallBack, is_animation_active){
  $1('body').classList.add("is_form--success");
  setTimeout(function(){
    $1('body').classList.remove("is_form--loading");
    setTimeout(function(){
      $1('body').classList.remove("is_form--success");
    }, 500)
  }, 500)

  if(is_animation_active){
    // disableOverlay();
    // return false;
    // TOGLIMI

  TweenMax.set(letters, {rotationY:"-90deg", y: "-5px",opacity:0});
  TweenMax.set(tagline, {x: "-5px",opacity:0, onComplete: overlay.classList.remove("disabled")});
  TweenMax.set(ship, {x:-1800,rotation:18});
  // TweenMax.set(speedLineGroup, {drawSVG:'0% 0%'})

  var speedLineTimeline = new TimelineMax({repeat:-1});
  speedLineTimeline.timeScale(9);
  speedLineTimeline.addPause(400).play();

  var tl = new TimelineMax({repeat:-1});
  tl.to(ship, 1.8, {x:0,rotation:0,ease:Back.easeOut.config(0.3)})
  .to(bg_container, 0.8, {x: "0%",ease:Power2.easeInOut}, "-=0.5")
  .staggerTo(letters, 0.5, {rotationY: 0,y: 0,opacity:1}, 0.05, "-=0.8")
  .staggerTo(tagline, 0.5, {x: 0,opacity:1}, 0.05, "-=0.6")
  .to(ship, 2, {rotation:-24,ease:Power2.easeInOut})
  .to(ship, 1.2, {x:1800,rotation:5, ease:Back.easeIn.config(0.1)},'-=2')
  .staggerTo(both, 0.5, {rotationY: "90deg",x: "-5px",opacity:0},0.02, '-=2')
  .to(bg_container, 0.5, {x: "100%",ease:Power2.easeInOut, onComplete:function(){playHomeTimeline(disableOverlay())}}, "-=1.2")
  .add(speedLineTimeline, 0)


  } else {
    // SAFARI FALLBACK
    TweenMax.set(".c-vertical-navigation__line .line", {height:"100%"}) ;
    TweenMax.set(".c-vertical-navigation__line-2 .line", {height:"100%"}) ;
    TweenMax.set(letters, {rotationY:"-90deg", y: "-5px",opacity:0});
    TweenMax.set(tagline, {x: "-5px",opacity:0, onComplete: overlay.classList.remove("disabled")});

    var speedLineTimeline = new TimelineMax({repeat:-1});
    speedLineTimeline.timeScale(9);
    var tl = new TimelineMax({repeat:-1});
    tl.from(ship, 1.8, {x:-1800,rotation:20,ease:Back.easeOut.config(0.3)})
    .to(bg_container, 0.8, {x: "0%",ease:Power2.easeInOut}, "-=0.5")
    .staggerTo(letters, 0.5, {rotationY: 0,y: 0,opacity:1}, 0.05, "-=0.8")
    .staggerTo(tagline, 0.5, {x: 0,opacity:1}, 0.05, "-=0.6")
    .to(ship, 2, {rotation:-24,ease:Power2.easeInOut})
    .to(ship, 1.2, {x:1800,rotation:5, ease:Back.easeIn.config(0.1)},'-=2')
    .staggerTo(both, 0.5, {rotationY: "90deg",x: "-5px",opacity:0},0.02, '-=2')
    .to(bg_container, 0.6, {x: "100%",ease:Power2.easeInOut, onComplete:function(){disableOverlay()}}, "-=1")
    .add(speedLineTimeline, 0)

  }
}

export function loadPageOverlayIn(barbaCallBack){

  TweenMax.set(letters, {rotationY:"-90deg", y: "-5px",opacity:0});
  TweenMax.set(tagline, {x: "-5px",opacity:0, onComplete: overlay.classList.remove("disabled")});
  // TweenMax.set(speedLineGroup, {drawSVG:'0% 0%'})
  TweenMax.set(ship, {x:-1800,rotation:18});


  var speedLineTimeline = new TimelineMax({repeat:-1});
  speedLineTimeline.timeScale(9);

  var tl = new TimelineMax({repeat:-1});
  tl.to(ship, 1.8, {x:0,rotation:0,ease:Back.easeOut.config(0.3)})
  .to(bg_container, 0.8, {x: "0%",ease:Power2.easeInOut}, "-=2")
  .staggerTo(letters, 0.5, {rotationY: 0,y: 0,opacity:1}, 0.05, "-=0.8")
  .staggerTo(tagline, 0.5, {x: 0,opacity:1}, 0.05, "-=0.6",function(){barbaCallBack()})
  .add(speedLineTimeline, 0)

}

export function loadPageOverlayOut(){
    initTimelines();
    var speedLineTimeline = new TimelineMax({repeat:-1});
    speedLineTimeline.timeScale(9);

    var tl = new TimelineMax({repeat:-1});
    tl.to(ship, 1.2, {rotation:-24,ease:Power2.easeInOut})
    .to(ship, 1.2, {x:1800,rotation:5, ease:Back.easeIn.config(0.1)},'-=1.2')
    .staggerTo(both, 0.5, {rotationY: "90deg",x: "-5px",opacity:0},0.02,'-=1')
    .to(bg_container, 0.6, {x: "100%",ease:Power2.easeInOut, onComplete:function(){playHomeTimeline(disableOverlay())}}, "-=0.6")
    .add(speedLineTimeline, 0)
}

function disableOverlay(){
  overlay.classList.add("disabled")
  TweenMax.set(bg_container, {x: "-100%"})
  TweenMax.set(ship, {x: "0"})
}



export function showSuccessFormAnimation(form_id){
  const form_name = $1(form_id)

  // var speedLineTimeline = new TimelineMax({repeat:-1});
  // speedLineTimeline.timeScale(9);
  // var tl = new TimelineMax({repeat:-1});
  // tl.fromTo(form_name.querySelector(".c-contact-form--loader"), 0.3, {opacity: 1}, {opacity:0})
  // .fromTo(form_name.querySelector(".c-contact-form--thanks-message"), 0.3, {opacity:0},{opacity: 1})
  // .add(speedLineTimeline, 0)

  // speedLineTimeline.addPause(3000).play()

  // tl.fromTo(form_name.querySelector(".c-contact-form--thanks-message"), 0.3, {opacity:1},{opacity: 0})
  // .fromTo(form_name.querySelector(".c-contact-form--content"), 0.3, {opacity:0},{opacity: 1})
  // .add(speedLineTimeline, 0)
}
export function showSendingFormAnimation(form_id){
  const form_name = $1(form_id)

  // var speedLineTimeline = new TimelineMax({repeat:-1});
  // speedLineTimeline.timeScale(9);
  // var tl = new TimelineMax({repeat:-1});
  // tl.fromTo(form_name.querySelector(".c-contact-form--content"), 0.3, {opacity: 1}, {opacity:0})
  // .fromTo(form_name.querySelector(".c-contact-form--loader"), 0.3, {opacity:0},{opacity: 1})
  // .add(speedLineTimeline, 0)

}
